import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
    {
        path: '/',
        component: () => import('@/components/Index'),
        hidden: true
    },
    {
        path: '/claim/policySelect',
        component: () => import('@/components/claim/PolicySelect'),
        hidden: true
    },
    {
        path: '/claim/policyList',
        component: () => import('@/components/claim/PolicyList'),
        hidden: true
    },

    {
        path: '/claim/claimAdd',
        component: () => import('@/components/claim/ClaimAdd'),
        hidden: true
    },

    {
        path: '/claim/claimSelect',
        component: () => import('@/components/claim/ClaimSelect'),
        hidden: true
    },

    {
        path: '/claim/claimList',
        component: () => import('@/components/claim/ClaimList'),
        hidden: true
    },

    {
        path: '/claim/claimInfo',
        component: () => import('@/components/claim/ClaimInfo'),
        hidden: true
    },

    {
        path: '/404',
        component: () => import('@/components/error/404'),
        hidden: true
    },
    {
        path: '/claim/claimInsert',
        component: () => import('@/components/claim/ClaimInsert'),
        hidden: true
    },
    {
        path: '/claim/claimEdit',
        component: () => import('@/components/claim/ClaimEdit'),
        hidden: true
    },
    {
        path: '/insure/productList',
        component: () => import('@/components/insure/ProductList'),
        hidden: true
    },
    {
        path: '/insure/insure',
        component: () => import('@/components/insure/Insure'),
        hidden: true
    },
    {
        path: '/insure/paySuccess',
        component: () => import('@/components/insure/PaySuccess'),
        hidden: true
    },
    {
        path: '/order/select',
        component: () => import('@/components/insure/OrderSelect'),
        hidden: true
    },
    {
        path: '/order/list',
        component: () => import('@/components/insure/OrderList'),
        hidden: true
    },
    {
        path: '/claim/examQr',
        component: () => import('@/components/claim/ClaimExamQr'),
        hidden: true
    },
    {
        path: '/claim/examSelect',
        component: () => import('@/components/claim/ClaimExamSelect'),
        hidden: true
    },
    {
        path: '/claim/examList',
        component: () => import('@/components/claim/ClaimExamList'),
        hidden: true
    }
]


const createRouter = () => new Router({
    routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
